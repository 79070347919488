.wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  padding: 32px;
}

.output {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  max-width: 1000px;
}


.uploaderCfg {
  --ctx-name: 'uploader';

  /* DO NOT FORGET TO USE YOUR OWN PUBLIC KEY */
  --cfg-pubkey: "20bdf094da37f6646276";
  --cfg-multiple: 1;
  --cfg-confirm-upload: 1;
  --cfg-img-only: 0;
  --cfg-accept: "";
  --cfg-store: 1;
  --cfg-camera-mirror: 0;
  --cfg-source-list: "local, url, camera, dropbox, gdrive";
  --cfg-max-files: 10;
  
}

.config {
  --cfg-pubkey: '20bdf094da37f6646276';
  --ctx-name: 'uploader-ctx';
  --darkmode: 0;
  --cfg-multiple: 0;
}